import React, { useState } from 'react'
import { Container, Grid, Icon, IconButton, Paper, Typography } from '@mui/material'
import { PedidoInterface, PedidoPagamentoInterface, rsERPCondicaoPagamentoInterface } from '../../../ImportBackend/Interfaces/PedidoInterfaces'
import ComboBox from '../../../DevComponents/ComboBox'
import InputText from '../../../DevComponents/InputText'
import ClsValidacao from '../../../Utils/ClsValidacao'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import { EMDESENVOLVIMENTO } from '../../../ImportBackend/Config/emDesenvolvimento'

interface PropsInterface {
  rsPedido: PedidoInterface
  rsCondicoesPagamento: Array<rsERPCondicaoPagamentoInterface>
  onSetPagamentos: ( pagamentos: Array<PedidoPagamentoInterface> ) => void
}

export default function PedidosPagamentos ( { rsPedido, rsCondicoesPagamento, onSetPagamentos }: PropsInterface ) {

  const ResetDados: PedidoPagamentoInterface = {
    tipoPagamentoNFeIdentificador: 0,
    idPedido: 0,
    idCondicaoPagamento: 0,
    idMeioPagamento: 0,
    valor: 0
  }

  const [rsDados, setRsDados] = useState<PedidoPagamentoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const clsFormato: ClsFormatacao = new ClsFormatacao()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idCondicaoPagamento', rsDados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'valor', rsDados, erros, retorno )

    if ( retorno ) {
      const total = rsPedido.pagamentos.reduce( ( acc, pagamento ) => acc + pagamento.valor, 0 );

      if ( parseFloat( ( total + rsDados.valor ).toFixed( 2 ) ) > rsPedido.vrLiquido ) {
        erros['valor'] = 'O valor total dos pagamentos não pode exceder o valor total do pedido.';
        retorno = false;
      }

    }

    setErros( erros )

    return retorno

  }

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idCondicaoPagamento',
      cabecalho: 'Condição de Pagamento',
      alinhamento: 'left',
      format: ( rs ) => rsCondicoesPagamento.find( ( condicao ) => condicao.ID_CONDICAO_PAGAMENTO === rs )?.DESCRICAO
    },

    {
      campo: 'valor',
      cabecalho: 'Valor',
      alinhamento: 'right',
      format: ( rs: number ) => clsFormato.currency( typeof rs === 'number' ? rs : 0 )
    }
  ]

  const btIncluirPagamento = () => {

    if ( validarDados() ) {

      const tmpPagamentos = [...rsPedido.pagamentos]
      const indice: number = rsPedido.pagamentos.findIndex( ( pagamento ) => pagamento.idCondicaoPagamento === rsDados.idCondicaoPagamento )

      if ( indice >= 0 ) {
        tmpPagamentos[indice] = { ...rsDados }
      } else {
        tmpPagamentos.push( { ...rsDados } )
      }

      onSetPagamentos( tmpPagamentos )

      setRsDados( ResetDados )

    }

  }

  const btExcluirCondicaoPagamento = ( rs: PedidoPagamentoInterface ) => {

    onSetPagamentos( rsPedido.pagamentos.filter( ( pagamento ) => pagamento.idCondicaoPagamento !== rs.idCondicaoPagamento ) )

  }

  const onChangeCondicaoPagamento = ( rs: rsERPCondicaoPagamentoInterface ) => {

    const valorPago: number = rsPedido.pagamentos.reduce( ( acc, pagamento ) => acc + pagamento.valor, 0 )

    setRsDados( {
      ...rsDados,
      idCondicaoPagamento: rs.ID_CONDICAO_PAGAMENTO,
      tipoPagamentoNFeIdentificador: rs.ID_TIPO_PAGAMENTO,
      idMeioPagamento: rs.ID_MEIO_PAGAMENTO,
      valor: parseFloat( ( rsPedido.vrLiquido - valorPago ).toFixed( 2 ) )
    } )

  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Pagamentos
                <Typography variant="body2" gutterBottom>
                  Condições de Pagamento
                </Typography>
              </Typography>

            </Grid>

            <Grid item xs={12} md={8}>

              <ComboBox
                setState={setRsDados}
                opcoes={rsCondicoesPagamento}
                campoDescricao='DESCRICAO'
                campoID='ID_CONDICAO_PAGAMENTO'
                dados={rsDados}
                field='idCondicaoPagamento'
                label='Condição de Pagamento'
                mensagemPadraoCampoEmBranco='Selecione Condição de Pagamento'
                permitirNovaOpcao={false}
                erros={erros}
                onChange={( rs: rsERPCondicaoPagamentoInterface ) => onChangeCondicaoPagamento( rs )}
              />

            </Grid>

            <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

              <InputText
                dados={rsDados}
                field='valor'
                label='Valor'
                textAlign='right'
                setState={setRsDados}
                tipo='currency'
                erros={erros}
              />

            </Grid>

            <Grid item xs={12} md={1} sx={{ pl: { md: 1 }, textAlign: { xs: 'right', md: 'center' } }}>

              <IconButton sx={{ mx: 0, px: 0, mt: 5 }} onClick={() => btIncluirPagamento()}>
                <Icon>add</Icon>
              </IconButton>

            </Grid>


            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={rsPedido.pagamentos} cabecalho={Cabecalho}
                acoes={
                  [
                    { icone: 'delete', toolTip: 'Excluir', onAcionador: btExcluirCondicaoPagamento },
                    // { icone: 'create', toolTip: 'Alterar', onAcionador: clsCrud.btEditar }
                  ]
                }
              />
            </Grid>

          </Grid>
        </Paper>

        <ExibirJSONDev exibir={EMDESENVOLVIMENTO && true} oque={[
          'Dados Condicao Pagamento', rsDados, 'rsPagamentos', rsPedido.pagamentos, 'rsCondicoesPagamento', rsCondicoesPagamento
        ]} />

      </Container>

    </>
  )

}